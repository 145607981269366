import React from 'react';
import Client1 from '../../assets/image/saas/testimonial/client-1.jpg';
import Client2 from '../../assets/image/agency/client/denny.png';

import Link from 'reusecore/src/elements/Link';

export const Faq = [
  {
    id: 1,
    expend: true,
    title: 'Come avete fatto a calcolare dei dati così precisi?',
    description:
      'Quella che vedi è una simulazione, che si basa su una distribuzione del tipo carte stimata in base alla media nazionale. Nel tuo caso specifico gli importi reali potrebbero differire.'
  },
  {
    id: 2,
    title: 'Quale distribuzione di tipo di carte avete usato?',
    description:
      'Abbiamo stimato che del totale delle transazioni il 24% avvenga con carta di credito (VISA, Mastercard), il 16% con carta di debito, il 20% con una carta prepagata e il 6% con una carta commercial. Il PagoBancomat, che è molto diffuso in Italia, occupa il 34% del totale.'
  }
];

export const allFAQs = [
  {
    id: 1,
    title: 'Come funziona il servizio?',
    description: (
      <>
        SignorPOS compara tutte le migliori offerte POS aiutando gli esercenti
        ad effettuare una scelta consapevole e permettendo un risparmio annuo
        fino a 9000 euro*. Per effettuare un preventivo immediato è sufficiente
        seguire questi semplici passi:
        <br />
        1. <b>Inserisci il transato annuo</b> che accetti con POS o, se la tua
        attività è nuova, una stima di quanto pensi di transare con il POS nel
        corso di un anno;
        <br />
        2. <b>Inserisci pochi e semplici dati</b> che ci aiutano a rendere il
        tuo preventivo il più preciso possibile rispetto alle tue esigenze;
        <br />
        3. <b>Scegli l’offerta più conveniente</b> da un elenco di offerte che
        ti proponiamo ordinate per costo annuo stimato e attivala subito online
        o al telefono. Le nostre offerte sono le migliori sul mercato e godono
        di sconti esclusivi dedicati agli utenti che usano SignorPOS.
      </>
    )
  },
  {
    id: 2,
    title: 'Quanto costa il vostro servizio?',
    description:
      'SignorPOS.it è gratis e lo resterà sempre per tutti i clienti che richiedono un preventivo o attivano un’offerta tramite il nostro portale. Richiedere un preventivo, inoltre, non implica alcun obbligo o impegno contrattuale da parte dell’utente.'
  },
  {
    id: 3,
    title: 'Come sono calcolati i costi annuali?',
    description: (
      <>
        Il nostro algoritmo considera tutte le voci di costo di ciascuna offerta
        e ti espone il costo annuale di ogni soluzione POS, in modo da rendere
        semplice il confronto le une con le altre, senza costi nascosti o spese
        impreviste. <br />
        Le offerte presenti su SignorPOS sono costantemente aggiornate per
        garantirti i preventivi più convenienti del momento, sempre.
      </>
    )
  },
  {
    id: 4,
    title: 'Quali voci di costo incidono sulla spesa annuale di un POS?',
    description: (
      <>
        I costi annuali vengono calcolati dal nostro algoritmo prendendo in
        considerazione tutte le voci di costo presenti in ciascuna offerta, in
        modo da garantirti{' '}
        <b>
          il miglior prezzo senza sorprese, costi nascosti o clausole poco
          chiare
        </b>
        . Queste sono le voci che il nostro algoritmo tiene in considerazione
        per ciascuna offerta: - costo di installazione del POS (se presente)
        <br />
        - costo di spedizione POS (se presente)
        <br />
        - costo di attivazione (se presente)
        <br />
        - canone mensile (se presente)
        <br />
        - commissioni % sulle transazioni e per i vari circuiti(se presenti)
        <br />
        - commissioni fisse sulle transazioni (se presenti)
        <br />
        - costi una tantum in caso di mancato raggiungimento di un transato
        minimo (se presente)
        <br />
        - altri costi non legati al transato (se presenti)
        <br />
      </>
    )
  },
  {
    id: 5,
    title: 'Quanto è possibile risparmiare?',
    description: (
      <>
        L’entità del risparmio varia a seconda del volume di transato annuo del
        cliente. Si parte da un risparmio di poche decine di euro per chi ha un
        transato annuo molto basso, fino a 10.000 euro per chi ha un volume di
        transato di 1.000.000 euro. Scopri quanto è il tuo risparmio potenziale
        inserendo i dati della tua attività{' '}
        <Link href="/step-2">
          <b>nell’apposito form</b>
        </Link>
        !
      </>
    )
  },
  {
    id: 6,
    title: 'Quanto sono precisi i vostri calcoli?',
    description: (
      <>
        Come specificato sopra, prendiamo in considerazione tutti i possibili
        costi previsti dal contratto per ogni tipologia di offerta. Nonostante
        questo, è possibile che i costi a consuntivo possano leggermente variare
        rispetto alle nostre stime, a seconda della distribuzione del transato
        rispetto ai vari circuiti.
      </>
    )
  },
  {
    id: 7,
    title: 'Come posso conoscere i dettagli di ciascuna offerta POS?',
    description: (
      <>
        È sufficiente seguire tutti i passaggi illustrati al{' '}
        <Link href="#accordion__heading-1">
          <b>punto 1</b>
        </Link>{' '}
        qui sopra per arrivare alla pagina di confronto di tutti i POS e
        cliccare su “Vedi dettagli” sotto ciascuna offerta.
      </>
    )
  },
  {
    id: 8,
    title: 'Come faccio a capire quanto costa il mio attuale POS?',
    description: (
      <>
        Per sapere quanto stai spendendo con il tuo POS e capire se puoi
        risparmiare cambiando offerta, ti consigliamo di:
        <br />
        - consultare i tuoi ultimi estratti conto mensili delle commissioni
        acquiring cercando di capire quanto transazioni accetti con il POS e
        quanto paghi mensilmente;
        <br />
        - consultare le fatture del tuo POS cercando di capire se e quanto paghi
        di canone mensile/trimestrale;
        <br />
        - consultare i tuoi estratti conto bancari per avere un’idea degli
        addebiti/accrediti che ti arrivano dalla tua banca o fornitore POS;
        <br />
        - richiedere a chi ti fornisce il POS un Documento di sintesi di tutte
        le condizioni economiche del tuo contratto POS.
        <br />A questo punto hai tutto gli elementi per capire quanto paghi
        durare un anno e confrontare la tua attuale offerta con i preventivi di
        SignorPOS. Scrivici a{' '}
        <Link href="mailto:ciao@signorpos.it" external>
          <b>ciao@signorpos.it</b>
        </Link>{' '}
        se hai bisogno di aiuto o{' '}
        <Link href="/step-2">
          <b>compila il form</b>
        </Link>{' '}
        per essere richiamato e guidato telefonicamente.
      </>
    )
  }
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-money-bag',
    title: 'Completamente gratuito',
    description: `Non solo confrontiamo le migliori
      offerte sul mercato ed i prezzi più
      convenienti del web: forniamo un
      servizio completamente gratuito senza
      alcuna commissione per il cliente.`
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: 'Il N.1 in Italia',
    description: `SignorPOS è il primo e unico
      comparatore di offerte POS in Italia.
      Siamo nati per portare trasparenza nel
      mondo dell'accettazione dei pagamenti
      e guidare gli esercenti nella
      giungla delle commissioni.`
  },
  {
    id: 3,
    icon: 'flaticon-briefing',
    title: 'Rispetta la tua privacy',
    description: `Utilizziamo i tuoi dati esclusivamente
      per fornirti il miglior servizio. Non
      vendiamo i tuoi contatti a società terze
      e non ti telefoniamo per proporti
      servizi diversi da quelli che ci hai
      richiesto.`
  }
];

export const Features_OLD = [
  {
    id: 1,
    icon: 'flaticon-security',
    title: 'Comodità e sicurezza',
    description:
      'Con la tecnologia Contactless, bastano pochi secondi per incassare un pagamento con carta e ti dimentichi delle code alla cassa. I pagamenti elettronici sono molto più sicuri di un pagamento in contanti. Puoi dire addio a contraffazione, costo di gestione del contante e rischio furti.'
  },
  {
    id: 2,
    icon: 'flaticon-group',
    title: 'Attira più clienti',
    description:
      'I clienti che non possono utilizzare da te la propria carta potrebbero rinunciare all’acquisto o effettuarlo presso un tuo concorrente.'
  },
  {
    id: 3,
    icon: 'flaticon-briefing',
    title: 'Un mare di informazioni',
    description:
      'Tutti i principali fornitori di POS ti consentono di accedere ad app o gestionali che ti forniscono dati preziosissimi sui tuoi ricavi, sulle performance della tua attività e sui tuoi clienti e le loro abitudini di spesa.'
  }
];

export const Footer_Data = [
  {
    title: 'Chi siamo',
    menuItems: [
      {
        url: '/blog',
        text: 'Blog'
      },
      {
        url: 'mailto:ciao@signorpos.it',
        text: 'Centro assistenza',
        external: true
      },
      {
        url: '/faq',
        text: 'Domande frequenti'
      }
    ]
  },
  {
    title: 'Informazioni',
    menuItems: [
      {
        url: '/privacy-policy',
        text: 'Privacy Policy'
      },
      {
        url: '/termini-condizioni',
        text: 'Termini e condizioni'
      }
    ]
  }
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'App Development',
    description:
      'We are specialized at custom Saas Application Development and special features .'
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: '10 Times Award',
    description:
      'We are globally recognised for our services and won a lot of prices around the world .'
  },
  {
    id: 3,
    icon: 'flaticon-atom',
    title: 'Cloud Stroage',
    description:
      'LiteSpeed Web Server known for its high performance and low resource consumption.'
  },
  {
    id: 4,
    icon: 'flaticon-ruler',
    title: 'Client Satisfaction',
    description:
      'Client Satisfaction is our first priority and We are best at it .Keep In Touch.'
  },
  {
    id: 5,
    icon: 'flaticon-creative',
    title: 'UX Planning',
    description:
      'We provide the best UI/UX Design by following the latest trends of the market  .'
  },
  {
    id: 6,
    icon: 'flaticon-conversation',
    title: 'Customer Support',
    description:
      'A Dedicated support team is always ready to provide best support to our customers.'
  }
];

export const Timeline = [
  {
    title: 'Inserisci il tuo incasso annuo con POS',
    description: ''
  },
  {
    title: 'Completa il modulo con pochi semplici dati sulla tua attività',
    description: ''
  },
  {
    title: 'Confronta le migliori offerte e scegli la più conveniente per te',
    description: ''
  }
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    avatar_url: Client1,
    social_icon: 'flaticon-instagram'
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    avatar_url: Client2,
    social_icon: 'flaticon-twitter-logo-silhouette'
  }
];

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '/',
    staticLink: true
  },
  {
    label: 'Blog',
    path: '/blog',
    staticLink: true
  },
  {
    label: 'FAQ',
    path: '/faq',
    staticLink: true
  }
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For Small teams or group who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder'
      },
      {
        content: '1,000s of Templates Ready'
      },
      {
        content: 'Blog Tools'
      },
      {
        content: 'eCommerce Store '
      },
      {
        content: '30+ Webmaster Tools'
      }
    ]
  },
  {
    name: 'Business Account',
    description: 'For Mediums teams or group who need to build website ',
    price: '$9.87',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder'
      },
      {
        content: '1,000s of Templates Ready'
      },
      {
        content: 'Blog Tools'
      },
      {
        content: 'eCommerce Store '
      },
      {
        content: '30+ Webmaster Tools'
      }
    ]
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder'
      },
      {
        content: '1,000s of Templates Ready'
      },
      {
        content: 'Blog Tools'
      },
      {
        content: 'eCommerce Store '
      },
      {
        content: '30+ Webmaster Tools'
      }
    ]
  }
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder'
      },
      {
        content: '1,000s of Templates Ready'
      },
      {
        content: 'Blog Tools'
      },
      {
        content: 'eCommerce Store '
      },
      {
        content: '30+ Webmaster Tools'
      }
    ]
  },
  {
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Unlimited secure storage'
      },
      {
        content: '2,000s of Templates Ready'
      },
      {
        content: 'Blog Tools'
      },
      {
        content: '24/7 phone support'
      },
      {
        content: '50+ Webmaster Tools'
      }
    ]
  },
  {
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'START FREE TRIAL',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder'
      },
      {
        content: '3,000s of Templates Ready'
      },
      {
        content: 'Advanced branding'
      },
      {
        content: 'Knowledge base support'
      },
      {
        content: '80+ Webmaster Tools'
      }
    ]
  }
];

export const posTypes = [
  {
    label: 'Smart',
    id: 1,
    description:
      'POS evoluto con schermo touch a colori, sistema operativo Android e possibilità di installare App utili per il tuo business'
  },
  {
    label: 'Cordless',
    id: 2,
    description:
      "Terminale standard con batteria e con tecnologia Wi-Fi che consente di spostarsi all'interno del punto vendita"
  },
  {
    label: 'Fisso',
    id: 3,
    description:
      "Terminale standard connesso all'elettricità e alla linea telefonica tramite cavo"
  },
  {
    label: 'Portatile',
    id: 5,
    description:
      'Terminale con batteria e SIM dati inclusa che consente di essere utilizzato ovunque'
  },
  {
    label: 'Mobile',
    id: 4,
    description:
      'Terminale di piccole dimensioni che può funzionare solo collegato ad uno smartphone o ad un tablet, anche in mobilità'
  }
];

const iZettleFees = [
  [1000, 0.0275],
  [2000, 0.0261],
  [3000, 0.0237],
  [4000, 0.0219],
  [5000, 0.0206],
  [6000, 0.0196],
  [7000, 0.0188],
  [8000, 0.0183],
  [9000, 0.0179],
  [10000, 0.0176],
  [11000, 0.0173],
  [12000, 0.0172],
  [13000, 0.017],
  [14000, 0.0168],
  [15000, 0.0162],
  [16000, 0.0156],
  [17000, 0.015],
  [18000, 0.0145],
  [19000, 0.0141],
  [20000, 0.0136],
  [21000, 0.0132],
  [22000, 0.0127],
  [23000, 0.0124],
  [24000, 0.0122],
  [25000, 0.012],
  [26000, 0.0118],
  [27000, 0.0116],
  [28000, 0.0114],
  [29000, 0.0112],
  [30000, 0.011],
  [31000, 0.0108],
  [32000, 0.0106],
  [33000, 0.0104],
  [34000, 0.0102],
  [35000, 0.01]
];

export const getPOSType = id => {
  const found = posTypes.find(e => e.id === id);

  return found && found.label;
};

export const calcIZettleFees = amount => {
  if (amount <= iZettleFees[0][0]) return iZettleFees[0][1];

  // Finding threshold
  const index = iZettleFees.findIndex(([threshold]) => amount <= threshold);

  return iZettleFees[index !== -1 ? index : iZettleFees.length - 1][1];
};

export const solutions = {
  1: {
    id: 1,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi',
    offerName: 'Classic +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi&a=classic',
    offerPage: '/nexi/smartpos-wifi-classic-plus',
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 79,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone'
      }
    ],
    maxAmount: false,
    minAmount: false,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      mastercard: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      pagoBancomat: 0.0069,
      jcb: 0.0229,
      upi: 0.0229
    },
    textFeatures: [
      {
        text: 'Accetta JCB e UPI',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Lettore QR code',
        positive: true
      },
      {
        text: 'Ideale per alimentari, botteghe e piccoli commercianti',
        positive: true
      }
    ]
  },
  2: {
    id: 2,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi',
    offerName: 'Italia +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi&a=italia',
    offerPage: '/nexi/smartpos-wifi-italia-plus',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 79,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      mastercard: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      pagoBancomat: 0.0099,
      jcb: 0.0249,
      upi: 0.0249
    },
    textFeatures: [
      {
        text: 'Accetta JCB e UPI',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Lettore QR code',
        positive: true
      },
      {
        text: 'Ideale per ristoranti, retail e servizi',
        positive: true
      }
    ]
  },
  3: {
    id: 3,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS Mini',
    offerName: 'Classic +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+mini+con+stampante&a=classic',
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone'
      }
    ],
    maxAmount: false,
    minAmount: false,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      mastercard: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      pagoBancomat: 0.0069,
      jcb: 0.0229,
      upi: 0.0229
    },
    textFeatures: [
      {
        text: 'Accetta JCB e UPI',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Lettore QR code',
        positive: true
      },
      {
        text: 'Ideale per alimentari, botteghe e piccoli commercianti',
        positive: true
      }
    ]
  },
  4: {
    id: 4,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS Mini',
    offerName: 'Italia +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+mini+con+stampante&a=italia',
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone'
      }
    ],
    maxAmount: false,
    minAmount: false,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      mastercard: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      pagoBancomat: 0.0099,
      jcb: 0.0249,
      upi: 0.0249
    },
    textFeatures: [
      {
        text: 'Accetta JCB e UPI',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Lettore QR code',
        positive: true
      },
      {
        text: 'Ideale per ristoranti, retail e servizi',
        positive: true
      }
    ]
  },
  5: {
    id: 5,
    enabled: false,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: 'Hi-POS Android',
    offerName: 'Tech',
    posTypes: [1],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    offerPage: '/axepta/tech',
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 19.9,
        label: 'Commissione mensile'
      }
    ],
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    additionalPaymentNetworksLabel:
      'Apple Pay, GPay, Alipay, WeChat Pay, UnionPay',
    variableFeesLabels: {
      visa: {
        credit: '0,89%*',
        debit: '0,89%*',
        prepaid: '0,89%*',
        commercial: '0,89%*'
      },
      mastercard: {
        credit: '0,89%*',
        debit: '0,89%*',
        prepaid: '0,89%*',
        commercial: '0,89%*'
      },
      pagoBancomat: '0,89%'
    },
    tableAdditionalInfo:
      "*Le commissioni si applicano alle carte emesse da paesi dell'area economica europea (EEA). Per le carte emesse da paesi non EEA si applica la commissione dell'1,95%.",
    variableFees: {
      visa: {
        credit: 0.0089,
        debit: 0.0089,
        prepaid: 0.0089,
        commercial: 0.0089
      },
      mastercard: {
        credit: 0.0089,
        debit: 0.0089,
        prepaid: 0.0089,
        commercial: 0.0089
      },
      pagoBancomat: 0.0089,
      jcb: 0.0275,
      upi: 0.0275
    },
    textFeatures: [
      {
        text: 'POS Android evoluto',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Accetta servizi di pagamento innovativi',
        positive: true
      },
      {
        text: 'Lettore QR Code/Barcode',
        positive: true
      }
    ]
    //promoMessage: 'OFFERTA BLACK FRIDAY: -10€ AL MESE'
  },
  6: {
    id: 6,
    enabled: false,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: 'Hi-POS DUO',
    offerName: 'Pro',
    posTypes: [4],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    offerPage: '/axepta/pro',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 9.9,
        label: 'Commissione mensile'
      }
    ],
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFeesLabels: {
      visa: {
        credit: '0,99%*',
        debit: '0,99%*',
        prepaid: '0,99%*',
        commercial: '0,99%*'
      },
      mastercard: {
        credit: '0,99%*',
        debit: '0,99%*',
        prepaid: '0,99%*',
        commercial: '0,99%*'
      },
      pagoBancomat: '0,99%'
    },
    tableAdditionalInfo:
      '*Commissione % per transazione con carte paesi dell’area economica europea. Con carte da Paesi non dell’area economica europea, la commissione % per transazione è di 1,95%.',
    variableFees: {
      visa: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0099
      },
      mastercard: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0099
      },
      pagoBancomat: 0.0099,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      // {
      //   text: 'Scelta tra terminale GPRS o mobile',
      //   positive: true
      // },
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      }
    ]
  },
  7: {
    id: 7,
    enabled: false,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: 'Hi-POS DUO',
    offerName: 'Start',
    posTypes: [4],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    offerPage: '/axepta/start',
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    specialFees: [
      {
        value: 79,
        label: 'Commissione di gestione con transato inferiore a 6.000€/anno'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Commissione mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: amount => (amount >= 6000 / 12 ? 0 : 79),
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFeesLabels: {
      visa: {
        credit: '1,25%*',
        debit: '1,25%*',
        prepaid: '1,25%*',
        commercial: '1,25%*'
      },
      mastercard: {
        credit: '1,25%*',
        debit: '1,25%*',
        prepaid: '1,25%*',
        commercial: '1,25%*'
      },
      pagoBancomat: '1,25%'
    },
    tableAdditionalInfo:
      "*Le commissioni si applicano alle carte emesse da paesi dell'area economica europea (EEA). Per le carte emesse da paesi non EEA si applica la commissione dell'1,95%.",
    variableFees: {
      visa: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      mastercard: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      pagoBancomat: 0.0125,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      // {
      //   text: 'Scelta tra terminale GPRS o mobile',
      //   positive: true
      // },
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      }
    ]
  },
  8: {
    id: 8,
    enabled: false,
    vendorName: 'Axerve',
    vendorId: 3,
    deviceName: '',
    offerName: 'Easy',
    posTypes: [3],
    vendorLogo: 'https://www.axerve.com/images/logos/logo-color-positive.svg',
    emailLogo: '/images/email/axerve-squared.png',
    pageUrl:
      'https://www.axerve.com/punto-vendita/pos/acquisition-pos-easy?utm_source=signorpos&utm_medium=affiliation&utm_content=poseasy&utm_campaign=acquisition2021',
    offerPage: '/axerve/easy-15',
    logoHeight: '45px',
    firstYearFixedCost: 0,
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      },
      {
        value: 16,
        label: 'Imposta di bollo'
      }
    ],
    monthlyFees: [
      {
        value: 15,
        label: 'Commissione mensile'
      }
    ],
    maxAmount: 10000,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      mastercard: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      pagoBancomat: 0,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'SIM con traffico dati inclusa',
        positive: true
      }
    ]
  },
  9: {
    id: 9,
    enabled: false,
    vendorName: 'Axerve',
    vendorId: 3,
    deviceName: '',
    offerName: 'Easy',
    posTypes: [3],
    vendorLogo: 'https://www.axerve.com/images/logos/logo-color-positive.svg',
    emailLogo: '/images/email/axerve-squared.png',
    pageUrl:
      'https://www.axerve.com/punto-vendita/pos/acquisition-pos-easy?utm_source=signorpos&utm_medium=affiliation&utm_content=poseasy&utm_campaign=acquisition2021',
    offerPage: '/axerve/easy-20',
    logoHeight: '45px',
    firstYearFixedCost: 0,
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      },
      {
        value: 16,
        label: 'Imposta di bollo'
      }
    ],
    monthlyFees: [
      {
        value: 20,
        label: 'Commissione mensile'
      }
    ],
    maxAmount: 30000,
    minAmount: 10000,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      mastercard: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      pagoBancomat: 0,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'SIM con traffico dati inclusa',
        positive: true
      }
    ]
  },
  10: {
    id: 10,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: 'Smeraldo',
    posTypes: [3],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 59,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0265,
        debit: 0.0265,
        prepaid: 0.0265,
        commercial: 0.0265
      },
      mastercard: {
        credit: 0.0265,
        debit: 0.0265,
        prepaid: 0.0265,
        commercial: 0.0265
      },
      pagoBancomat: 0.0265,
      jcb: 0.0265,
      upi: 0.0265
    }
  },
  11: {
    id: 11,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: 'Oro',
    posTypes: [3],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 79,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    }
  },
  12: {
    id: 12,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: 'Diamante',
    posTypes: [3],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 129,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      mastercard: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      pagoBancomat: 0.0125,
      jcb: 0.0125,
      upi: 0.0125
    }
  },
  13: {
    id: 13,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: 'Smeraldo',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 9,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0265,
        debit: 0.0265,
        prepaid: 0.0265,
        commercial: 0.0265
      },
      mastercard: {
        credit: 0.0265,
        debit: 0.0265,
        prepaid: 0.0265,
        commercial: 0.0265
      },
      pagoBancomat: 0.0265,
      jcb: 0.0265,
      upi: 0.0265
    }
  },
  14: {
    id: 14,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: 'Oro',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 19,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    }
  },
  15: {
    id: 15,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: 'Diamante',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 79,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      mastercard: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      pagoBancomat: 0.0125,
      jcb: 0.0125,
      upi: 0.0125
    }
  },
  16: {
    id: 16,
    enabled: true,
    vendorName: 'Zettle',
    vendorId: 5,
    deviceName: 'Zettle Reader',
    offerName: '',
    posTypes: [4],
    vendorLogo: '/images/zettle_logo.png',
    emailLogo: '/images/email/izettle-squared.png',
    pageUrl: 'http://izettle.go2cloud.org/aff_c?offer_id=226&aff_id=31189',
    logoHeight: '65px',
    fullFirstYearFixedCost: 79,
    oneTimeFees: [
      {
        value: 29,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, American Express',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFeesLabels: {
      visa: {
        credit: '1%-2,75%',
        debit: '1%-2,75%',
        prepaid: '1%-2,75%',
        commercial: '1%-2,75%'
      },
      mastercard: {
        credit: '1%-2,75%',
        debit: '1%-2,75%',
        prepaid: '1%-2,75%',
        commercial: '1%-2,75%'
      },
      pagoBancomat: 'Non accettato'
    },
    variableFees: calcIZettleFees,
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
      // {
      //   text: "Necessario uno smartphone per l'utilizzo",
      //   positive: false
      // }
    ]
  },
  17: {
    id: 17,
    enabled: true,
    vendorName: 'Monte dei Paschi di Siena',
    vendorId: 6,
    deviceName: 'Fisso/cordless',
    offerName: 'Start',
    posTypes: [2, 3],
    vendorLogo: 'https://www.mps.it/includes/img/monte-dei-paschi-di-siena.png',
    emailLogo: '/images/email/mps-squared.png',
    pageUrl:
      'https://www.mps.it/small-business/pos-ecommerce/p-o-s-per-il-punto-vendita.html',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 66.5,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 17.5,
        label: 'Canone mensile'
      }
    ],
    calcAmount: amount => (amount >= 1000 ? amount - 1000 : 0),
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.012,
        debit: 0.012,
        prepaid: 0.012,
        commercial: 0.012
      },
      mastercard: {
        credit: 0.012,
        debit: 0.012,
        prepaid: 0.012,
        commercial: 0.012
      },
      pagoBancomat: 0.012,
      jcb: 0.012,
      upi: 0.012
    }
  },
  18: {
    id: 18,
    enabled: true,
    vendorName: 'Axerve',
    vendorId: 3,
    deviceName: '',
    offerName: 'Easy a canone',
    posTypes: [1],
    posTypesAlternativeLabels: ['Smart POS PAX A920 con tecnologia Android™'],
    vendorLogo: 'https://www.axerve.com/images/logos/logo-color-positive.svg',
    emailLogo: '/images/email/axerve-squared.png',
    pageUrl:
      'https://www.axerve.com/punto-vendita/pos?utm_source=signorpos&utm_medium=affiliation&utm_content=poseasy&utm_campaign=acquisition2021',
    offerPage: '/axerve/easy-a-canone',
    logoHeight: '45px',
    oneTimeFees: [
      {
        value: 16,
        label: 'Imposta di bollo (una tantum)'
      },
      {
        value: 0,
        label: 'Acquisto POS'
      }
    ],
    specialFees: [
      {
        label: 'Canone mensile',
        valueLabel:
          '17€ iva esclusa fino a 10.000€ di transato <br /> 22€ iva esclusa  oltre i 10.000€',
        value: 0
      }
    ],
    maxAmount: 30000,
    minAmount: false,
    monthlySubscriptionFee: (amount, month) => {
      if (amount === 0) return 17;

      const changeFeeMonth = Math.ceil(10000 / amount);

      if (month > changeFeeMonth) {
        return 22;
      }

      return 17;
    },
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    calcAmount: amount => (amount >= 30000 / 12 ? amount - 30000 / 12 : 0),
    calcFixedFee: 0,
    variableFeesLabels: {
      visa: {
        credit: '0%*',
        debit: '0%*',
        prepaid: '0%*',
        commercial: '0%*'
      },
      mastercard: {
        credit: '0%*',
        debit: '0%*',
        prepaid: '0%*',
        commercial: '0%*'
      },
      pagoBancomat: '0%*'
    },
    tableAdditionalInfo:
      "*Si applica una commissione dell'1.5% in caso di superamento dei 30.000€ solo sulla parte eccedente i 30.000€",
    variableFees: {
      visa: {
        credit: 0.015,
        debit: 0.015,
        prepaid: 0.015,
        commercial: 0.015
      },
      mastercard: {
        credit: 0.015,
        debit: 0.015,
        prepaid: 0.015,
        commercial: 0.015
      },
      pagoBancomat: 0.015,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'Traffico 4G incluso',
        positive: true
      }
    ]
  },
  19: {
    id: 19,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: '',
    posTypes: [3],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 129,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  20: {
    id: 20,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: '',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 79,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  21: {
    id: 21,
    enabled: true,
    vendorName: 'MyPOS',
    vendorId: 7,
    deviceName: 'Cordless',
    offerName: 'Go',
    posTypes: [2],
    vendorLogo: 'https://www.mypos.eu/img/info/logo-header.png',
    emailLogo: '/images/email/mypos-squared.png',
    pageUrl:
      'https://shop.mypos.eu/it/19-pos-mobile/183-go?a_aid=5e53b312579ac',
    logoHeight: '67px',
    oneTimeFees: [
      {
        value: 39,
        label: 'Acquisto/Installazione'
      },
      {
        value: 15,
        label: 'Spedizione'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, American Express',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0175,
        debit: 0.0175,
        prepaid: 0.0175,
        commercial: 0.0175
      },
      mastercard: {
        credit: 0.0175,
        debit: 0.0175,
        prepaid: 0.0175,
        commercial: 0.0175
      },
      pagoBancomat: 0.0175,
      jcb: 0.0175,
      upi: 0.0175
    },
    textFeatures: [
      {
        text: 'Compresa una carta di debito',
        positive: true
      },
      {
        text: 'Accetti pagamenti anche online',
        positive: true
      },
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  22: {
    id: 22,
    enabled: false,
    vendorName: 'Moneynet',
    vendorId: 8,
    deviceName: '',
    offerName: 'Fix 25',
    posTypes: [3],
    vendorLogo: '/images/moneynet.png',
    emailLogo: '/images/email/moneynet-squared.png',
    offerPage: `/moneynet/fix-25`,
    pageUrl: 'https://www.moneynet.it/',
    logoHeight: '67px',
    oneTimeFees: [
      {
        value: 50,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 25,
        label: 'Canone mensile'
      }
    ],
    maxAmount: 30000,
    minAmount: false,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      mastercard: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      pagoBancomat: 0,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Canone fisso e senza sorprese',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      }
    ]
  },
  23: {
    id: 23,
    enabled: false,
    vendorName: 'Moneynet',
    vendorId: 8,
    deviceName: '',
    offerName: 'Fix 45',
    posTypes: [3],
    vendorLogo: '/images/moneynet.png',
    emailLogo: '/images/email/moneynet-squared.png',
    pageUrl: 'https://www.moneynet.it/',
    offerPage: '/moneynet/fix-45',
    logoHeight: '67px',
    oneTimeFees: [
      {
        value: 50,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 45,
        label: 'Canone mensile'
      }
    ],
    maxAmount: 100000,
    minAmount: 30000,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      mastercard: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      pagoBancomat: 0,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Canone fisso e senza sorprese',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      }
    ]
  },
  24: {
    id: 24,
    enabled: false,
    vendorName: 'Moneynet',
    vendorId: 8,
    deviceName: '',
    offerName: 'Flash',
    posTypes: [3],
    vendorLogo: '/images/moneynet.png',
    emailLogo: '/images/email/moneynet-squared.png',
    pageUrl: 'https://www.moneynet.it/',
    offerPage: '/moneynet/flash',
    logoHeight: '67px',
    oneTimeFees: [
      {
        value: 50,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 10,
        label: 'Canone mensile'
      }
    ],
    maxAmount: 100000,
    minAmount: 30000,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0085,
        debit: 0.0073,
        prepaid: 0.0073,
        commercial: 0.0085
      },
      mastercard: {
        credit: 0.0085,
        debit: 0.0073,
        prepaid: 0.0073,
        commercial: 0.0085
      },
      pagoBancomat: 0.0049,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      }
    ]
  },
  25: {
    id: 25,
    enabled: false,
    vendorName: 'Moneynet',
    vendorId: 8,
    deviceName: '',
    offerName: 'Free',
    posTypes: [3],
    vendorLogo: '/images/moneynet.png',
    emailLogo: '/images/email/moneynet-squared.png',
    pageUrl: 'https://www.moneynet.it/',
    offerPage: '/moneynet/free',
    logoHeight: '67px',
    firstYearFixedCost: 0,
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: 100000,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    variableFees: {
      visa: {
        credit: 0.0079,
        debit: 0.0069,
        prepaid: 0.0069,
        commercial: 0.0079
      },
      mastercard: {
        credit: 0.0079,
        debit: 0.0069,
        prepaid: 0.0069,
        commercial: 0.0079
      },
      pagoBancomat: 0.0045,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      }
    ]
  },
  26: {
    id: 26,
    enabled: true,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: '',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 29,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, American Express',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  27: {
    id: 27,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: 'Air',
    offerName: 'Pro',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 79,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0145,
        debit: 0.0145,
        prepaid: 0.0145,
        commercial: 0.0145
      },
      mastercard: {
        credit: 0.0145,
        debit: 0.0145,
        prepaid: 0.0145,
        commercial: 0.0145
      },
      pagoBancomat: 0.0145,
      jcb: 0.0145,
      upi: 0.0145
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
      // {
      //   text: "Necessario uno smartphone per l'utilizzo",
      //   positive: false
      // }
    ]
  },
  28: {
    id: 28,
    enabled: true,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: '',
    posTypes: [3],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 99,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, American Express',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      mastercard: {
        credit: 0.0195,
        debit: 0.0195,
        prepaid: 0.0195,
        commercial: 0.0195
      },
      pagoBancomat: 0.0195,
      jcb: 0.0195,
      upi: 0.0195
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  29: {
    id: 29,
    enabled: false,
    vendorName: 'SumUp',
    vendorId: 4,
    deviceName: '3G',
    offerName: 'Pro',
    posTypes: [4],
    vendorLogo: 'https://upload.wikimedia.org/wikipedia/en/4/42/SumUp_logo.png',
    emailLogo: '/images/email/sumup-squared.png',
    pageUrl: 'https://sumup.it/sumupconsultant/PCG3FPE2',
    oneTimeFees: [
      {
        value: 129,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: 0,
    variableFeesLabels: {
      pagoBancomat: 'Non accettato'
    },
    variableFees: {
      visa: {
        credit: 0.0145,
        debit: 0.0145,
        prepaid: 0.0145,
        commercial: 0.0145
      },
      mastercard: {
        credit: 0.0145,
        debit: 0.0145,
        prepaid: 0.0145,
        commercial: 0.0145
      },
      pagoBancomat: 0.0145,
      jcb: 0.0145,
      upi: 0.0145
    },
    textFeatures: [
      {
        text: 'Nessun vincolo contrattuale',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto',
        positive: true
      },
      {
        text: 'Non accetta PagoBancomat',
        positive: false
      }
    ]
  },
  30: {
    id: 30,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'Mobile POS',
    offerName: 'Welcome',
    posTypes: [4],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://merchant.nexi.it/acquista-online.php',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 0,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 29,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    calcFixedFee: (monthlyAmount, monthlyFees) => Math.max(37.5 - monthlyFees * 12, 0), // se fai meno di 37.5€ di fees in un anno, ti scalano il costo del programma Protection Plus
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0189,
        debit: 0.0189,
        prepaid: 0.0189,
        commercial: 0.0189
      },
      mastercard: {
        credit: 0.0189,
        debit: 0.0189,
        prepaid: 0.0189,
        commercial: 0.0189
      },
      pagoBancomat: 0.0189,
      jcb: 0.0239,
      upi: 0.0239
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      }
    ]
  },
  31: {
    id: 31,
    enabled: !!process.env.SHOW_BROKEN_OFFERS,
    broken: true,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: '',
    offerName: 'Offerta senza ID per test',
    posTypes: [5, 4, 2],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    offerPage: '/axepta/test',
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFees: {
      visa: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      mastercard: {
        credit: 0,
        debit: 0,
        prepaid: 0,
        commercial: 0
      },
      pagoBancomat: 0,
      jcb: 0,
      upi: 0
    }
  },
  32: {
    id: 32,
    enabled: false,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: 'POS Fisso/POS Portatile',
    offerName: 'Pro',
    posTypes: [5, 3, 2],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    offerPage: '/axepta/pro',
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    maxAmount: false,
    minAmount: false,
    monthlyFees: [
      {
        value: 9.9,
        label: 'Commissione mensile'
      }
    ],
    calcFixedFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFeesLabels: {
      visa: {
        credit: '0,99%*',
        debit: '0,99%*',
        prepaid: '0,99%*',
        commercial: '0,99%*'
      },
      mastercard: {
        credit: '0,99%*',
        debit: '0,99%*',
        prepaid: '0,99%*',
        commercial: '0,99%*'
      },
      pagoBancomat: '0,99%'
    },
    tableAdditionalInfo:
      "**Le commissioni si applicano alle carte emesse da paesi dell'area economica europea (EEA). Per le carte emesse da paesi non EEA si applica la commissione dell'1,95%.",
    variableFees: {
      visa: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0099
      },
      mastercard: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0099
      },
      pagoBancomat: 0.0099,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'Scelta tra terminale fisso o terminale portatile',
        positive: true
      }
    ]
  },
  33: {
    id: 33,
    enabled: false,
    vendorName: 'Axepta BNP Paribas',
    vendorId: 2,
    deviceName: 'POS Fisso/POS Portatile',
    offerName: 'Start',
    posTypes: [5, 3, 2],
    vendorLogo:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAABCCAQAAADUfAOuAAAAdUlEQVR42u3RQQEAAAQEMJdcdDr42ios08UzkS4d6UhHOtKRjnSkIx3pSEc60pGOdKQjXTrSkY50pCMd6UhHOtKRjnSkIx3pSEe6dKQjHelIRzrSkY50pCMd6UhHOtKRjnTpSEc60pGOdKQjHelIRzrSkc7VAhDWYr8lSTp7AAAAAElFTkSuQmCC',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl: process.env.GATSBY_AXEPTA_URL,
    formInOfferPage: true,
    skipOfferPage: process.env.GATSBY_SKIP_OFFER_PAGE,
    offerPage: '/axepta/start',
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      }
    ],
    specialFees: [
      {
        value: 79,
        label: 'Commissione di gestione con transato inferiore a 6.000€/anno'
      }
    ],
    maxAmount: false,
    minAmount: false,
    monthlyFees: [
      {
        value: 0,
        label: 'Commissione mensile'
      }
    ],
    calcFixedFee: amount => (amount >= 6000 / 12 ? 0 : 79),
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFeesLabels: {
      visa: {
        credit: '1,25%*',
        debit: '1,25%*',
        prepaid: '1,25%*',
        commercial: '1,25%*'
      },
      mastercard: {
        credit: '1,25%*',
        debit: '1,25%*',
        prepaid: '1,25%*',
        commercial: '1,25%*'
      },
      pagoBancomat: '1,25%'
    },
    tableAdditionalInfo:
      "*Le commissioni si applicano alle carte emesse da paesi dell'area economica europea (EEA). Per le carte emesse da paesi non EEA si applica la commissione dell'1,95%.",
    variableFees: {
      visa: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      mastercard: {
        credit: 0.0125,
        debit: 0.0125,
        prepaid: 0.0125,
        commercial: 0.0125
      },
      pagoBancomat: 0.0125,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'Scelta tra terminale fisso o terminale portatile',
        positive: true
      }
    ]
  },
  34: {
    id: 34,
    enabled: !!process.env.SHOW_BETA_OFFERS,
    vendorName: 'Banca Sella',
    vendorId: 9,
    deviceName: '',
    offerName: '',
    posTypes: [2, 3, 5],
    vendorLogo: '/images/bancasella.svg',
    emailLogo: '/images/email/axepta-squared.png',
    pageUrl:
      'https://www.sella.it/onboarding/fe/03268/pos/#/start/POS_DITTA_IND',
    formInOfferPage: false,
    skipOfferPage: false,
    offerPage: '/sella/pos',
    oneTimeFees: [
      {
        value: 0,
        label: 'Acquisto/Installazione'
      },
      {
        value: 0,
        label: 'Spedizione'
      },
      {
        value: 16,
        label: 'Bollo'
      }
    ],
    specialFees: [],
    monthlyFees: [
      {
        value: 0,
        label: 'Canone mensile'
      }
    ],
    maxAmount: false,
    minAmount: false,
    calcFixedFee: amount => (amount >= 6000 ? 0 : 79),
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat, American Express',
    variableFees: {
      visa: {
        credit: 0.0095,
        debit: 0.0095,
        prepaid: 0.0095,
        commercial: 0.0095
      },
      mastercard: {
        credit: 0.0095,
        debit: 0.0095,
        prepaid: 0.0095,
        commercial: 0.0095
      },
      pagoBancomat: 0.0045,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Scelta tra terminale Fisso, Cordless, WiFi, GEM e GPRS',
        positive: true
      },
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      }
    ]
  },
  35: {
    id: 35,
    enabled: true,
    vendorName: 'Axerve',
    vendorId: 3,
    deviceName: '',
    offerName: 'Easy a commissioni',
    posTypes: [1],
    posTypesAlternativeLabels: ['Smart POS PAX A920 con tecnologia Android™'],
    vendorLogo: 'https://www.axerve.com/images/logos/logo-color-positive.svg',
    emailLogo: '/images/email/axerve-squared.png',
    pageUrl:
      'https://www.axerve.com/punto-vendita/pos-easy-commissioni?utm_source=signorpos&utm_medium=affiliation&utm_content=poseasy&utm_campaign=acquisition2021',
    offerPage: '/axerve/easy-a-commissioni',
    logoHeight: '45px',
    oneTimeFees: [
      {
        value: 16,
        label: 'Imposta di bollo (una tantum)'
      },
      {
        value: 170,
        label: 'Acquisto POS'
      }
    ],
    specialFees: [
      {
        label: 'Canone mensile',
        value: 0
      }
    ],
    maxAmount: false,
    minAmount: false,
    monthlySubscriptionFee: 0,
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    calcFixedFee: 0,
    variableFees: {
      visa: {
        credit: 0.01,
        debit: 0.01,
        prepaid: 0.01,
        commercial: 0.01
      },
      mastercard: {
        credit: 0.01,
        debit: 0.01,
        prepaid: 0.01,
        commercial: 0.01
      },
      pagoBancomat: 0.01,
      jcb: 0,
      upi: 0
    },
    textFeatures: [
      {
        text: 'Si appoggia su qualsiasi conto corrente aperto in Italia',
        positive: true
      },
      {
        text: 'Nessuna penale in caso di disdetta',
        positive: true
      },
      {
        text: 'Traffico 4G incluso',
        positive: true
      }
    ]
  },
  36: {
    id: 36,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS Mini',
    offerName: 'Start',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+mini+con+stampante&a=start',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone mensile'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      mastercard: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      pagoBancomat: 0.0120,
      jcb: 0.0270,
      upi: 0.0270
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      },
      {
        text: 'Ideale per piccole attività',
        positive: true
      }
    ]
  },
  37: {
    id: 37,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi',
    offerName: 'Start',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi&a=start',
    offerPage: '/nexi/smartpos-wifi-start',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 79,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 14.5,
        label: 'Canone mensile'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      mastercard: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      pagoBancomat: 0.0120,
      jcb: 0.0270,
      upi: 0.0270
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      },
      {
        text: 'Ideale per piccole attività',
        positive: true
      }
    ]
  },
  38: {
    id: 38,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi + 4G',
    offerName: 'Start',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi+4g&a=start',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 19.5,
        label: 'Canone mensile'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      mastercard: {
        credit: 0.0120,
        debit: 0.0120,
        prepaid: 0.0120,
        commercial: 0.0270
      },
      pagoBancomat: 0.0120,
      jcb: 0.0270,
      upi: 0.0270
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      },
      {
        text: 'Ideale per piccole attività',
        positive: true
      }
    ]
  },
  39: {
    id: 39,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi + 4G',
    offerName: 'Classic +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi+4g&a=classic',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 19.5,
        label: 'Canone mensile'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      mastercard: {
        credit: 0.0119,
        debit: 0.0119,
        prepaid: 0.0119,
        commercial: 0.0199
      },
      pagoBancomat: 0.0069,
      jcb: 0.0229,
      upi: 0.0229
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      },
      {
        text: 'Ideale per alimentari, botteghe e piccoli commercianti',
        positive: true
      }
    ]
  },
  40: {
    id: 40,
    enabled: true,
    vendorName: 'Nexi',
    vendorId: 1,
    deviceName: 'SmartPOS WiFi + 4G',
    offerName: 'Italia +',
    posTypes: [1],
    vendorLogo:
      'https://nexi.it/content/dam/nexi/img/logo/logo--dark-double.svg',
    emailLogo: '/images/email/nexi-squared.png',
    pageUrl: 'https://www.nexi.it/shop/cart/nexi-smartpos.html?m=nexi+smartpos+wifi+4g&a=italia',
    maxAmount: false,
    minAmount: false,
    oneTimeFees: [
      {
        value: 7.5,
        label: 'Programma Protection Plus (una tantum)'
      },
      {
        value: 99,
        label: 'Acquisto/Installazione'
      }
    ],
    monthlyFees: [
      {
        value: 2.5,
        label: 'Programma Protection Plus (al mese)'
      },
      {
        value: 19.5,
        label: 'Canone mensile'
      }
    ],
    acceptedPaymentNetworksLabel:
      'Visa, VPay, MasterCard, Maestro, PagoBancomat',
    additionalPaymentNetworksLabel: 'Apple Pay, GPay, UnionPay',
    variableFees: {
      visa: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      mastercard: {
        credit: 0.0099,
        debit: 0.0099,
        prepaid: 0.0099,
        commercial: 0.0249
      },
      pagoBancomat: 0.0099,
      jcb: 0.0249,
      upi: 0.0249
    },
    textFeatures: [
      {
        text: 'Assistenza telefonica 24/7',
        positive: true
      },
      {
        text: 'Pay-by-Link incluso per pagamenti a distanza',
        positive: true
      },
      {
        text: 'Ideale per ristoranti, retail e servizi',
        positive: true
      }
    ]
  },
};

export const getSolution = id => solutions[id] || {};

const distributions = {
  visa: {
    credit: 0.12,
    debit: 0.08,
    prepaid: 0.1,
    commercial: 0.03
  },
  mastercard: {
    credit: 0.12,
    debit: 0.08,
    prepaid: 0.1,
    commercial: 0.03
  },
  pagoBancomat: 0.34,
  jcb: 0,
  upi: 0
};

export const sum = arr =>
  arr.reduce(function(a, b) {
    return a + b;
  }, 0);

export const calcTransactionFees = ({ amount, solutionId } = { amount: 0 }) => {
  const solution = solutions[solutionId];

  if (!solution) throw Error('Solution not found');

  const {
    variableFees = 0,
    firstYearFixedCost = 0,
    monthlySubscriptionFee = 0,
    acquiringCommission = 0,
    calcAmount,
    calcFixedFee = 0,
    shipping = 0,
    monthlyFees,
    oneTimeFees
  } = solution;

  const realAmount =
    typeof calcAmount === 'function' ? calcAmount(amount) : amount;

  const calculatedVariableFees =
    typeof variableFees === 'function' ? variableFees(amount) : variableFees;

  let fees = 0;
  if (typeof calculatedVariableFees === 'number') {
    fees += calculatedVariableFees * realAmount;
  } else {
    fees += Object.entries(calculatedVariableFees).reduce(
      (acc, [vendor, fees]) => {
        if (typeof fees === 'object') {
          const currentVendorFees = Object.entries(fees).reduce(
            (acc, [cardType, fee]) => {
              const currentVendorCardTypeFees =
                realAmount * distributions[vendor][cardType] * fee;

              return acc + currentVendorCardTypeFees;
            },
            0
          );

          return acc + currentVendorFees;
        }

        const currentVendorFees = realAmount * distributions[vendor] * fees;

        return acc + currentVendorFees;
      },
      0
    );
  }

  const roundedFees = Number(fees.toFixed(2));

  const firstMonth = Number(
    (
      firstYearFixedCost +
      (oneTimeFees ? sum(oneTimeFees.map(({ value }) => value)) : 0) +
      (monthlyFees && monthlyFees.length > 0
        ? sum(monthlyFees.map(({ value }) => value))
        : 0) +
      (typeof monthlySubscriptionFee === 'function'
        ? monthlySubscriptionFee(amount, 1)
        : monthlySubscriptionFee) +
      roundedFees +
      acquiringCommission +
      shipping
    ).toFixed(2)
  );

  const nextMonths = month =>
    Number(
      (
        (monthlyFees && monthlyFees.length > 0
          ? sum(monthlyFees.map(({ value }) => value))
          : 0) +
        (typeof monthlySubscriptionFee === 'function'
          ? monthlySubscriptionFee(amount, month)
          : monthlySubscriptionFee) +
        acquiringCommission +
        roundedFees
      ).toFixed(2)
    );


  const firstYear = Number(
    (
      firstMonth + sum([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(nextMonths))
    ).toFixed(2)
  )

  const fixedFee =
    typeof calcFixedFee === 'function' ? calcFixedFee(amount, fees) : calcFixedFee;

  return {
    firstMonth,
    nextMonths: nextMonths(2),
    firstYear:
      firstYear + fixedFee,
    nextYears:
      Number(
        sum([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(nextMonths)).toFixed(2)
      ) + fixedFee
  };
};

export const getBestSolutions = amount => {
  return Object.entries(solutions)
    .filter(([solutionId, solution]) => solution.enabled)
    .map(([solutionId, solution]) => ({
      ...solution,
      ...calcTransactionFees({ amount, solutionId })
    }))
    .sort((a, b) => Math.sign(a.firstYear - b.firstYear))
    .filter(({ maxAmount, minAmount }) => {
      if (maxAmount) {
        if (maxAmount < amount * 12) return false;
      }

      if (minAmount) {
        if (minAmount >= amount * 12) return false;
      }

      return true;
    });
};
